import React from "react";
import SEO from "../components/seo";
import SliderBootstrap from "../components/SliderBootstrap";
import Services from "../components/Services";
import ContactForm from "../components/ContactForm";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

import about1 from "../images/about-us/clinical_research_1.jpg";
import about2 from "../images/about-us/clinical_research_2.jpg";
import about3 from "../images/about-us/clinical_research_3.jpg";
import about4 from "../images/about-us/clinical_research_4.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <div>
      <SEO
        title="Home"
        description="Savante Consulting Limited are regulatory affairs consultants in Africa, with operations in Nigeria and Kenya."
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />

      <SliderBootstrap />

      <section>
        <div className="container">
          <div>
            <h3 className="section-title flex-center">ABOUT US</h3>
          </div>

          <Grid container spacing={7} direction="row" justify="space-evenly">
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <img
                alt="Clinical Trials Black woman"
                className="responsive-img z-depth-2 br-3 mask z-depth-1-hover"
                src={about1}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <img
                alt="Clinical Trials, black man"
                className="responsive-img z-depth-2 br-3 mask z-depth-1-hover"
                src={about2}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <img
                alt="Clinical Trials black nurse"
                className="responsive-img z-depth-2 br-3 mask z-depth-1-hover"
                src={about3}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
              <img
                alt="Clinical Trials two black nurses"
                className="responsive-img z-depth-2 br-3 mask z-depth-1-hover"
                src={about4}
              />
            </Grid>
          </Grid>

          <Grid container spacing={7} direction="row" justify="space-evenly">
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <p className="justify fs-20">
                Savante Consulting Limited are regulatory Affairs Consultants in
                Africa, with operating offices in Nigeria and Kenya. Savante
                Consulting Limited provides services that include product
                registration and regulatory approvals for companies in the
                Pharmaceuticals, Biotechnology, Medical Devices, Food and
                Beverages Industries.{" "}
              </p>
              <p className="justify fs-20">
                Savante Consulting is also one of the leading Clinical Research
                Organizations in Nigeria. It will project-manage phase III and
                IV clinical trials, and provide Pharmacovigilance Services to
                support Marketing Authorization Applications.
              </p>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              
              <Paper style={{padding: 15, backgroundColor: '#3f51b512', marginBottom: 2 }}>
                  <Typography variant="h4" component="h2">
                  Schedule a Consultation
              </Typography>
                <ContactForm />  
              </Paper>
              
            </Grid>
          </Grid>
        </div>
      </section>

      <Services />
    </div>
  );
}
