import React from 'react';
import Slider from 'infinite-react-carousel'
import Grid from '@material-ui/core/Grid';
import TweenOne from 'rc-tween-one';
import './Slider.css'
import { Link } from 'gatsby';

export default class SliderBootstrap extends React.Component {
  render() {
    const settings =  {
      autoplay: true,
      dots: true,
      duration: 150
    };
    return (
      <div>
        <Slider { ...settings }>
          <div  className="jumbotron slider_1">
             <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className="slider-height"
              >
              <TweenOne className="banner-user-text" 
                      animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
                    >
                <h3 class="white-text slider-title">Clinical Trials</h3>
              </TweenOne>
              <TweenOne className="banner-user-text"
                      animation={{ y: 30, opacity: 0, type: 'from', delay: 180 }}
                    >
                  <Link className="btn btn-slider z-depth-4-hover br-3" to="/services/clinical-trails" >Learn More</Link>
              </TweenOne>
              </Grid>
          </div>
          <div  className="jumbotron slider_2">
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className="slider-height"
              >
               
               <TweenOne className="banner-user-text" 
                      animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
                    >
                <h3 class="white-text slider-title">Pharmacovigilance</h3>
              </TweenOne>
              <TweenOne className="banner-user-text"
                      animation={{ y: 30, opacity: 0, type: 'from', delay: 180 }}
                    >
                  <Link className="btn btn-slider z-depth-4-hover br-3" to="/services/pharmacovigilance" >Learn More</Link>
              </TweenOne>
            </Grid>
          </div>
          <div  className="jumbotron slider_3">
          <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className="slider-height"
              >
              

              <TweenOne className="banner-user-text" 
                      animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
                    >
                <h3 class="white-text slider-title">Regulatory Affairs</h3>
              </TweenOne>
              <TweenOne className="banner-user-text"
                      animation={{ y: 30, opacity: 0, type: 'from', delay: 180 }}
                    >
                  <Link className="btn btn-slider z-depth-4-hover br-3" to="/services/regulatory-affairs" >Learn More</Link>
              </TweenOne>
            </Grid>
          </div>
        </Slider>
      </div>
    );
  }
}