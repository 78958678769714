import { Link } from "gatsby"
import React from 'react';
import TweenOne from 'rc-tween-one';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import service_1 from '../images/services/Savente-Consulting-Limited-Services-01.jpg'
import service_2 from '../images/services/Savente-Consulting-Limited-Services-02.jpg'
import service_3 from '../images/services/Savente-Consulting-Limited-Services-03.jpg'
import { faArchive, faClinicMedical, faMedkit } from "@fortawesome/free-solid-svg-icons"

export default class Services extends React.Component{

    render(){
   
        return(
            <section>
            <div className="container">
                <div>
                    <h3 className="section-title flex-center">SERVICES</h3>
                </div>
                <Grid 
                    container 
                    spacing={7}  
                    direction="row"
                    justify="space-evenly"

                    
                >
      
        
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TweenOne animation={{ y: 0, opacity: 0, type: 'from', delay: 300 }} name="TweenOne1">
                                <div className="single-news">
                                    <div class="view overlay hm-white-slight">
                                        <Link to="/services/clinical-trails">
                                            <img alt="Clinical Trials" className="responsive-img z-depth-2 br-3 mask z-depth-1-hover" src={service_1} />
                                        </Link>
                                    </div>
                                    <h5 className="center"> 
                                        <Link to="/services/clinical-trails" className="pink-text center fs-20"> 
                                        <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faArchive} />
                                            Clinical Trials
                                        </Link>
                                    </h5>
                                </div>
                            </TweenOne>
                        </Grid>
                        

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <TweenOne animation={{ y: 0, opacity: 0, type: 'from', delay: 300 }} name="TweenOne1">
                            <div class="single-news">
                                <div class="view overlay hm-white-slight">
                                    <Link to="/services/regulatory-affairs">
                                        <img alt="Regulatory Affairs" className="responsive-img z-depth-2 br-3 mask z-depth-1-hover" src={service_2} />
                                    </Link>
                                </div>

                                
                                <h5 className="center"> 
                                    <Link to="/services/regulatory-affairs" className="pink-text center fs-20"> <FontAwesomeIcon className="mr-8"  style={{width: '1.125em'}}  aria-label="Mute volume" icon={faClinicMedical} /> Regulatory Affairs</Link>
                                </h5>
                        
                            </div>
                        </TweenOne>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <TweenOne animation={{ y: 0, opacity: 0, type: 'from', delay: 300 }} name="TweenOne1">
                            <div class="single-news">
                                <div class="view overlay hm-white-slight">
                                <Link to="/services/pharmacovigilance">
                                    <img alt="Pharmacovigilance" className="responsive-img z-depth-2 br-3 mask z-depth-1-hover" src={service_3} />
                                </Link>
                                    <a href="pharmacovigilance/">
                                        <div class="mask waves-effect waves-light"></div>
                                    </a>
                                </div>
                                    <h5 className="center"> 
                                        <Link to="/services/pharmacovigilance" className="pink-text center fs-20"> <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faMedkit} /> Pharmacovigilance</Link>
                                    </h5>
                        
                            </div>
                        </TweenOne>
                        </Grid>
                    
                </Grid>
                <br />
                <br />
            </div>
        </section>
        );
    }
}
